import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { albhmAchiev1,albhmAchiev2,albhmAchiev3,albhmAchiev4,albhmAchiev5,albhmAchiev6,albhmAchiev7,albhmAchiev8

} from "../../imagepath";
// import { Link } from "react-router-dom";

const Blog = () => {
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1170: {
        items: 4,
      },
    },
  };

  const blogs = [
	albhmAchiev1,
	albhmAchiev2,
	albhmAchiev3,
	albhmAchiev4,
	albhmAchiev5,
	albhmAchiev6,
	albhmAchiev7,
	albhmAchiev8
  ]

  return (
    <OwlCarousel
      {...settings}
      className="owl-carousel blogs-slide owl-theme aos"
      data-aos="fade-up"
    >

		{
			blogs.map((blog, index) => (
				<div className="instructors-widget blog-widget" key={index}>
					<div className="instructors-img">
						<img className="img-fluid" alt="" src={blog} />
					</div>
					<div className="instructors-content text-center">
					<h5>
						Achievement {index +1}
					</h5>
					<p>Albayan Islamic School</p>
					{/* <div className="student-count d-flex justify-content-center">
						<i className="fa-solid fa-calendar-days" />
						<span>Jun 15, 2024</span>
					</div> */}
					</div>
				</div>
			))
		}
    </OwlCarousel>
  );
};

export default Blog;
