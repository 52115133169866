import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import Header2 from "../header2/index-two";
// import { useSelector } from "react-redux";
import Blog from "./slider/blog";
import BannerSlider from "./slider/bannerSlider";
import TrendingCourse from "./slider/trendingCourse";
import { Footer3 } from "../footer3";

import {
  shape2,
  shape3,

  SkillsIcon01,
  SkillsIcon02,
  SkillsIcon03,
  albhmExtra1,
  albhmExtra2,
  albhmExtra3,
  albhmFlyer1,
  albhmFlyer2,
  albhmFlyer3,
  albhmFlyer4,
	PatternSvg5,
  PatternSvg6,
} from "../imagepath";
import AOS from "aos";
import "aos/dist/aos.css";
import { DailyActivities } from "./components/DailyActivities";
import { Testimonial2 } from "./slider/testimonial";

// const options = [
//   { label: "Select Category", value: "Category" },
//   { label: "Category One", value: "Category1" },
//   { label: "Category Two", value: "Category2" },
// ];

export const Home2 = () => {
//   const mobileSidebar = useSelector(
//     (state) => state.sidebarSlice.expandMenu
//   );
  useEffect(() => {
    document.body.className = "home-two";

    return () => {
      document.body.className = "";
    };
  }, []);

  // Select Option
//   const [setValue] = useState(null);
//   const style = {
//     control: (baseStyles, state) => ({
//       ...baseStyles,
//       backgroundColor:mobileSidebar === 'disabled' ? "white":"#2b2838",
//       border: state.isFocused ? 0 : 0,
//       paddingLeft: "5px",
//       marginTop:"1px",
//       // This line disable the blue border
//       boxShadow: state.isFocused ? 0 : 0,
//       borderRadius: state.isSelected ? "0" : "10px",
//       fontSize: "14px",
//       "&:hover": {
//         border: state.isFocused ? 0 : 0,
//         color: "black",
//       },
//       // borderRadius: "50px" ,
//       outline: "none",
//     }),
//     menu: (base) => ({ ...base, marginTop: "2px" }),
//     option: (provided) => ({
//       ...provided,
//       backgroundColor:  mobileSidebar === 'disabled' ? "#fff" : "#000",
//       color:mobileSidebar === 'disabled'? '#000':'#fff',
//       fontSize: "14px",
//       "&:hover": {
//         backgroundColor:mobileSidebar === 'disabled'? "#A8D5BA":"#2b2838",
//       },
//     }),
//     indicatorSeparator: (base) => ({
//       ...base,
//       display: "none",
//     }),
//     dropdownIndicator: (base, state) => ({
//       ...base,
//       color: "black",
//       transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
//       transition: "250ms",
//     }),
//   };

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);


//   const sliders = [
// 	albhmSlider1,
// 	albhmSlider2,
// 	albhmSlider3,
// 	albhmSlider4,
// 	albhmSlider5,

//   ]

  return (
    <>
      <div className="main-wrapper">
        <Header2 />
        {/* Home banner */}
        <section
          className="home-two-slide d-flex align-items-center"
        //   style={{ backgroundImage: mobileSidebar === 'disabled' && "url(" + albhmBG + ")",
		// 	backgroundRepeat: 'no-repeat',
		// 	backgroundSize: '100% 100%',
		// 	height: '100vh', width: '100vw' }}
        >
			<BannerSlider/>

          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-12" data-aos="fade-up">
                <div className="home-slide-face">

                  <div className="banner-content">

                  </div>

                </div>
              </div>
            </div>
            {/* Shapes  */}
            <div className="shapes">
              {/* <img className="shapes-one img-fluid" src={logoAlbhm} alt="" /> */}
              <img className="shapes-two" src={shape2} alt="" />
              <img className="shapes-middle" src={shape3} alt="" />

            </div>
            {/* Shapes */}
          </div>
        </section>
        {/* Home banner */}

        {/* About */}
        <section className="about-section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 wow fadeInLeft order-lg-1 order-xs-2 order-sm-2"
                data-aos="fade-up"
              >
                <div className="header-two-title">
                  <p className="tagline">Penerimaan Peserta Didik Baru </p>
                  <h2 className="mb-0">
                    Al Bayan Islamic School Makassar
                  </h2>
                </div>
                <div className="header-two-title">
                  <p className="about-text">
					Assalamu alaikum Warahmatullahi Wabarakatuh,<br /><br />

					Ahlan Wasahlan di Info PPDB Al Bayan Islamic School Hidayatullah Makassar!<br /><br />

					Al Bayan Islamic School Hidayatullah Makassar, merupakan sekolah Islam berbasis pondok pesantren, yang berdiri di kota Makassar, Sulawesi Selatan sejak tahun 1997.
				Sistem Pendidikan Al Bayan Islamic School Hidayatullah Makassar, mengintegrasikan kurikulum Nasional dan kurikulum Pendidikan Integral Berbasis Tauhid yang merupakan kurikulum ciri khas pesantren Hidayatullah yang berfokus pada pendekatan Aqliyah (Intelektual), Jismiyah (Keterampilan), dan Ruhiyah (Spiritual).<br /><br />

					Al Bayan Islamic School Hidayatullah Makassar, berkomitmen untuk mendidik generasi qur’ani, unggul dan berkarakter, serta siap menghadapi tantangan masa depan dengan fondasi keimanan dan keilmuan yang kuat.<br /><br />

					Untuk itu, dengan senang hati, kami menyambut ananda bersama orang tua untuk bergabung bersama keluarga besar Al Bayan Islamic School Hidayatullah Makassar.  <br /><br />

					Wassalamu alaikum Warahmatullahi Wabarakatuh.
				</p>

                  {/* <div className="about-button more-details">
                    <Link to="/course-list" className="discover-btn">
                      Learn more <i className="fas fa-arrow-right ms-2"></i>
                    </Link>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-6 order-lg-2 order-xs-1 order-sm-1">
                <div className="stylist-gallery">
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-12"
                      data-aos="fade-down"
                    >
                      <div className="instructors-img count-one d-flex align-items-center justify-content-center flex-fill project-details mb-2 ">
						{/* <img src={albhmFlyer1} alt="" className="img-fluid" /> */}
                          <div className="course-img" >
                            <img src={albhmFlyer1} alt="" className="img-fluid" />
                          </div>

                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-12"
                      data-aos="fade-down"
                    >
                      <div className="instructors-img count-two d-flex align-items-center justify-content-center flex-fill project-details mb-2">
						{/* <img src={albhmFlyer2} alt="" className="img-fluid" /> */}
                          <div className="course-img" >
                            <img src={albhmFlyer2} alt="img-fluid" className="img-fluid" />
                          </div>

                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-12"
                      data-aos="fade-right"
                      data-wow-delay="1.5"
                    >
                      <div className="instructors-img count-three d-flex align-items-center justify-content-center flex-fill project-details mb-2">
						{/* <img src={albhmFlyer3} alt="" className="img-fluid" /> */}
                          <div className="course-img" >
                            <img src={albhmFlyer3} alt="img-fluid" className="img-fluid" />
                          </div>

                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-12"
                      data-aos="fade-left"
                      data-wow-delay="0.5"
                    >
                      <div className="instructors-img count-four d-flex align-items-center justify-content-center flex-fill project-details mb-2">
						{/* <img src={albhmFlyer4} alt="" className="img-fluid" /> */}
                          <div className="course-img" >
                            <img src={albhmFlyer4} alt="img-fluid" className="img-fluid" />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About */}

		{/* Feature Instructors */}
        <TrendingCourse/>
        {/* Feature Instructors */}

		{/* Latest Blog */}
		<section
          className="section latest-blog"
        >
			<div className="container">
				<div className="section-header aos" data-aos="fade-up">
					<div className="section-sub-head feature-head text-center mb-0">
						<h2>Ekstrakurikuler Berprestasi</h2>
						<div className="section-text aos" data-aos="fade-up">
						<p className="mb-0">
							Tak hanya di bidang akademik, siswa Al-Bayan Islamic School juga mengukir prestasi di kegiatan ekstrakurikuler seperti olahraga, seni, dan keterampilan lainnya.
						</p>
						</div>
					</div>
				</div>
				<Blog />
			</div>

		</section>
		{/* Latest Blog */}

		{/* Master Skills */}
        <section className="master-skills-sec">
          <div className="container">
            <div className="header-two-title text-center" data-aos="fade-up">
              {/* <p className="tagline">albhm</p> */}
              <h2>Alasan Terbaik untuk Pendidikan Islami Berkualitas</h2>
              <div className="header-two-text" data-aos="fade-up">
                {/* <p className="mb-0">
                  Get certified, master modern tech skills, and level up your
                  career — whether you’re starting out or a seasoned pro. 95% of
                  eLearning learners report our hands-on content directly helped
                  their careers.
                </p> */}
              </div>
            </div>
            {/* Course Info */}
            <div className="course-info-two">
              <div className="row align-items-center">
                {/* Master Skills Content */}
                <div
                  className="col-lg-6 col-md-12 order-lg-0 order-md-0 order-0"
                  data-aos="fade-up"
                >
                  <div className="join-title-one">
                    <h2>Kurikulum Terpadu dan Islami</h2>
                    <p>
                      Al-Bayan Islamic School menggabungkan kurikulum nasional dengan pendidikan berbasis Islam, membentuk siswa yang cerdas, berakhlak, dan taat agama. Kami menanamkan nilai-nilai akhlak mulia sejak dini, membentuk karakter siswa yang jujur, disiplin, dan bertanggung jawab.<br/> Siswa kami berprestasi di bidang akademik dan keagamaan, membuktikan keunggulan pendidikan yang diberikan di Al-Bayan.
                    </p>

                  </div>
                </div>
                {/* Master Skills Content */}

                {/* Master Skills Image */}
                <div
                  className="col-lg-6 col-md-12 order-lg-1 order-md-1 order-1"
                  data-aos="fade-up"
                >
                  <div className="join-mentor-img">
                    <div className="winning-two-one">
                      <img src={albhmExtra1} alt="" className="img-fluid" />
                    </div>
                    <div className="joing-icon-award">
                      <img
                        src={SkillsIcon01}
                        alt=""
                        className="joing-icon-one img-fluid"
                      />
                    </div>
                  </div>
                </div>
                {/* Master Skills Image */}

                {/* Master Skills Content */}
                <div
                  className="col-xl-6 col-lg-7 col-md-12 order-lg-2 order-md-3 order-3 joinPos"
                  data-aos="fade-up"
                >
                  <div className="join-mentor-img">
                    <div className="winning-two-two">
                      <img src={albhmExtra2} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="joing-icon-award">
                    <img
                      src={SkillsIcon02}
                      alt=""
                      className="joing-icon-two img-fluid"
                    />
                  </div>
                </div>
                {/* Master Skills Content */}

                {/* Master Skills Image */}
                <div
                  className="col-xl-6 col-lg-5 col-md-12 order-lg-3 order-md-2 order-2"
                  data-aos="fade-up"
                >
                  <div className="join-title-middle">
                    <h2>Beragam Program Ekstrakurikuler</h2>
                    <p>
                      Kami menyediakan berbagai kegiatan ekstrakurikuler yang mengembangkan minat dan bakat siswa di bidang olahraga, seni, dan keterampilan lainnya.Dengan fasilitas lengkap dan lingkungan yang aman, kami memastikan siswa belajar dalam suasana yang nyaman dan mendukung perkembangan diri.
                    </p>
                  </div>
                </div>
                {/* Master Skills Image */}

                {/* Master Skills Content */}
                <div
                  className="col-xl-6 col-lg-7 col-md-12 order-lg-4 order-md-4 order-4"
                  data-aos="fade-up"
                >
                  <div className="join-title-one">
                    <h2>Pembinaan Akhlak dan Karakter</h2>
                    <p>
                      Di Al-Bayan, pendidikan karakter menjadi prioritas. Kami membina siswa untuk memiliki akhlak yang baik, sikap disiplin, dan rasa tanggung jawab sesuai dengan nilai-nilai Islami. Lingkungan yang Islami dan nyaman menjadikan Al-Bayan tempat ideal untuk tumbuh dan berkembangnya siswa dalam suasana yang mendukung pendidikan serta spiritualitas mereka.<br/>Al-Bayan menyediakan berbagai kegiatan ekstrakurikuler yang menyeimbangkan antara akademik dan minat bakat siswa, mengasah potensi dan keterampilan untuk masa depan.
                    </p>
                  </div>
                </div>
                {/* Master Skills Content */}

                {/* Master Skills Image */}
                <div
                  className="col-xl-6 col-lg-5 col-md-12 order-lg-5 order-md-5 order-5 joinPos"
                  data-aos="fade-up"
                >
                  <div className="join-mentor-img mb-0">
                    <div className="winning-two-three">
                      <img src={albhmExtra3} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="joing-icon-award">
                    <img
                      src={SkillsIcon03}
                      alt=""
                      className="joing-icon-three img-fluid"
                    />
                  </div>
                </div>
                {/* Master Skills Image */}
              </div>
            </div>
            {/* Course Info */}
          </div>
        </section>
        {/* Master Skills */}

		{/* Daily Activities */}
		<DailyActivities/>
		{/* Daily Activities */}


		{/* Testimonial */}
      <section className="testimonial-three">
        <div className="container">
          <div className="testimonial-pattern">
            <img className="pattern-left img-fluid" alt="" src={PatternSvg5} />
            <img className="pattern-right img-fluid" alt="" src={PatternSvg6} />
          </div>
          <div className="testimonial-three-content">
            <div className="row align-items-center">
              <div
                className="col-xl-4 col-lg-12 col-md-12"
                data-aos="fade-down"
              >
                <div className="become-content" >
                  <h2 className="aos-init aos-animate" style={{ color: "white" }}>They Trusted us</h2>
                  <h4 className="aos-init aos-animate" style={{ color: "white", fontSize: "20px" }}>
                    Al Bayan Islamic School Makassar
                  </h4>
                </div>

                {/* View all Testimonail */}
                {/* <Link
                  to="/instructor/instructor-profile"
                  className="btn btn-action aos-init aos-animate"
                  data-aos="fade-up"
                >
                  View all Testimonial
                </Link> */}
                {/* View all Testimonail */}
              </div>
              <div
                className="col-xl-8 col-lg-12 col-md-12"
                data-aos="fade-down"
              >
                {/* Swiper Element */}
                <div
                  className="swiper-testimonial-three"
                  style={{ width: "100%", height: "400px" }}
                >
                  <Testimonial2 />
                </div>
                {/* <Testimonial03 /> */}
                {/* Swiper Element */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonial */}


        {/* Footer */}
        <Footer3 />
        {/* Footer */}
      </div>
    </>
  );
};
