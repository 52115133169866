import { useState } from "react";
import { Link } from "react-router-dom";
import { albhmAchiev2, albhmAchiev3, albhmAchiev4, albhmAchiev5, albhmAchiev6, albhmExtra1, albhmExtra2, albhmExtra3 } from "../../imagepath";

const activities = [
  albhmExtra1,
  albhmAchiev2,
  albhmExtra2,
  albhmAchiev3,
  albhmExtra3,
  albhmAchiev4,
  albhmAchiev5,
  albhmAchiev6
];

export const DailyActivities = () => {
  const [toggle, setToggle] = useState(1);

  const updateToggle = (id) => {
    setToggle(id);
    console.log(id);
  };

  return (
    <section className="home-three-courses">
      <div className="container">
        <div className="favourite-course-sec">
          <div className="row">
            <div className="home-three-head section-header-title" data-aos="fade-up">
              <div className="row align-items-center d-flex justify-content-between">
                <div className="col-lg-6">
                  <h2>Daily Activities</h2>
                </div>
              </div>
            </div>

            <div className="all-corses-main">
              <div className="tab-content">
                <div className="nav tablist-three" role="tablist">
                  {['All', 'Hafalan', 'Pramuka', 'Futsal', 'Taekwondo', 'Olahraga', 'Senam'].map((tab, idx) => (
                    <Link
                      key={idx}
                      className={toggle === idx + 1 ? "nav-tab me-3 active" : "nav-tab me-3"}
                      data-bs-toggle="tab"
                      to={`#${tab.toLowerCase()}tab`}
                      role="tab"
                      onClick={(e) => {
                        e.preventDefault();
                        updateToggle(idx + 1);
                      }}
                    >
                      {tab}
                    </Link>
                  ))}
                </div>

                <div className="tab-content">
                  <div className={toggle === 1 ? "tab-pane fade active show" : "tab-pane fade"} id="alltab" role="tabpanel">
                    <div className="all-course">
                      <div className="row">
                        {activities.map((activity, index) => (
                          <div className="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up" key={index}>
                            <div className="course-box-three">
                              <div className="course-three-item">
                                <div className="course-three-img">
                                  <Link to="/">
                                    <img className="img-fluid" alt="" src={activity} />
                                  </Link>
                                  <div className="heart-three">
                                    <Link to="#">
                                      <i className="fa-regular fa-heart"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
