import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import {
	albhmFlyer1,
	albhmFlyer2,
	albhmFlyer3,
	albhmFlyer4,
	albhmFlyer5,
	albhmFlyer6,

} from "../../imagepath";
import { Link } from "react-router-dom";

const TrendingCourse = () => {
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1170: {
        items: 3,
      },
    },
  };

  const ppdbs = [
	{
		image: albhmFlyer1,
		link: "/albhm/ppdb/1",
		title: "PPDB Keunggulan TK Al-Wildan",
	},
	{
		image: albhmFlyer2,
		link: "/albhm/ppdb/1",
		title: "PPDB Keunggulan SD Integral",
	},
	{
		image: albhmFlyer3,
		link: "/albhm/ppdb/1",
		title: "PPDB Keunggulan SMP SMA Fullday",
	},
	{
		image: albhmFlyer4,
		link: "/albhm/ppdb/1",
		title: "PPDB Keunggulan SMP SMA Boarding",
	},
	{
		image: albhmFlyer5,
		link: "/albhm/ppdb/1",
		title: "PPDB Keunggulan PPTQ Putri",
	},
	{
		image: albhmFlyer6,
		link: "/albhm/ppdb/1",
		title: "PPDB Keunggulan PPTQ Putra",
	},
  ]

  return (
    <section
      className="section trend-course"
    >
      <div className="container">
        <div className="section-header aos" data-aos="fade-up">
          <div className="section-sub-head feature-head text-center mb-0">
            <h2>Pendaftaran Online Terbuka</h2>
			<div className="section-text aos" data-aos="fade-up">
			<p className="mb-0">
				Daftarkan putra-putri Anda dengan mudah melalui pendaftaran online. Al-Bayan Islamic School Makassar menyediakan kemudahan proses untuk bergabung menjadi bagian dari keluarga besar kami.
			</p>
        </div>
          </div>
        </div>

        <OwlCarousel
          {...settings}
          className="owl-carousel trending-course owl-theme aos"
          data-aos="fade-up"
        >
			{ppdbs.map((ppdb, index) => (
			<div className="course-box trend-box" key={index}>
				<div className="product trend-product">
				<div className="product-img">
					<Link to="course-details">
					<img className="img-fluid" alt="" src={ppdb.image} />
					</Link>
					{/* <div className="price">
						<h3>
							$200 <span>$99.00</span>
						</h3>
					</div> */}
				</div>
				<div className="product-content">

					<h3 className="title">
					<Link to="course-details">
						{ppdb.title}
					</Link>
					</h3>


					<div className="all-btn all-category d-flex align-items-center mt-3">
					{/* <Link to="/checkout" className="btn btn-primary"> */}
					<a href="https://forms.gle/Wgz48yhqJ6APzCZL6" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
						DAFTAR SEKARANG
					</a>

					</div>
				</div>
				</div>
			</div>
			))}


        </OwlCarousel>

      </div>
    </section>
  );
};

export default TrendingCourse;
