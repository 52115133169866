import React from "react";
import { Facebook, Linkedin, Twitter, Youtube } from "react-feather";
import { Link } from "react-router-dom";
import { logoAlbhm } from "../imagepath";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';



export const Footer3 = () => {
	const position = [-5.132444092671154, 119.50362518312429];

	L.Icon.Default.mergeOptions({
		iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
		iconUrl: require('leaflet/dist/images/marker-icon.png'),
		shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
		});
  return (
    <footer className="footer footer-three">
      {/* Footer Top */}
      <div className="footer-three-top" data-aos="fade-up">
        <div className="container">
          <div className="footer-three-top-content">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-8 col-12">
                {/* Footer Widget */}
                <div className="footer-widget-three footer-about">
                  <div className="footer-three-logo flex-row">
                    <img className="img-fluid" src={logoAlbhm} alt="logo" />
					<h6 className="text-white">Al-Bayan Islamic School Makassar</h6>
                  </div>
                  <div className="footer-three-about">
                    <MapContainer center={position} zoom={15} style={{ height: '400px', width: '100%' }}>
						<TileLayer
							url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						/>
						<Marker position={position}>
							<Popup>
								Al-Bayan Islamic School Makassar.
							</Popup>
						</Marker>
					</MapContainer>
                  </div>
                  {/* <div className="newsletter-title">
                    <h6>Get Updates</h6>
                  </div> */}

                </div>
                {/* Footer Widget */}
              </div>

				<div className="col-lg-4 col-md-4 col-12">
					<div className="footer-widget footer-contact footer-contact-two text-white">
						<h2 className="footer-title text-white">Address</h2>
						<div className="footer-contact-widget">
						<div className="footer-address">
							<h6>Location</h6>
							<p>
							{" "}
							BTP Bumi Tamalanrea Permai, Jl. Bumi Tamalanrea Permai Blok M No.26, Tamalanrea, Kec. Tamalanrea,
							<br />  Kota Makassar, Sulawesi Selatan 90245{" "}
							</p>
						</div>

						<h6>Email Address</h6>
						<p className="text-white">
							<Link to="mailto:dreamslms@example.com" className="text-white">
								admin.ppdb@albhm.com
							</Link>
						</p>

						<h6>Office Phone number</h6>
						<p className="mb-0 text-white">
							<Link to="tel:+6285298989742" className="text-white">(0411) 8958880</Link>
						</p>

						<h6>Admin Phone number</h6>
						<p className="mb-0 text-white">
							<Link to="tel:+6285298989742" className="text-white">081260606093</Link>
						</p>
						</div>
					</div>
				</div>

            </div>
          </div>
        </div>
      </div>
      {/* Footer Top */}

      {/* Footer Bottom */}
      <div className="footer-three-bottom" data-aos="fade-up">
        <div className="container">
          {/* Copyright */}
          <div className="copyright-three">
            <div className="row">
              <div className="col-md-12">
                <div className="social-icon-three">
                  <h6>Connect Socially</h6>
                  <ul>
                    <li>
                      <Link
                        to="/home"
                        target="_blank"
                        className="feather-facebook-icon"
                      >
                        <Facebook size={14} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home"
                        target="_blank"
                        className="feather-twitter-icon"
                      >
                        <Twitter size={14} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home"
                        target="_blank"
                        className="feather-linkedin-icon"
                      >
                        <Linkedin size={14} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home"
                        target="_blank"
                        className="feather-youtube-icon"
                      >
                        <Youtube size={14} />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="privacy-policy-three">
                  <ul>
                    <li>
                      <Link to="/">Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to="/">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <div className="copyright-text-three">
                  <p className="mb-0">
                    &copy; 2024 Al Bayan Islamic School Makassar. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Copyright */}
        </div>
      </div>
      {/* Footer Bottom */}
    </footer>
  );
};
