import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { albhmTesti1, albhmTesti2, albhmTesti3,  Quote } from "../../imagepath";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/swiper-bundle.min.css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";


const Slide1 = () => {
  return (
    <div className="swiper-slide">
      <div className="testimonial-item-five">
        <div className="testimonial-quote">
          <img className="quote img-fluid home3Slide" alt="" src={Quote} />
        </div>
        <div className="testimonial-content">
          <p>
            Saya sebagai orangtua siswa sangat bersyukur bisa menyekolahkan anak saya di SMP Islam Albayan Hidayutullah terjadi perubahan yg sangat signifikan dari pengembangan pribadi anak saya menjadi lebih mandiri, lebih taat dalam beribadah, dan yg lebih utama lagi Alhamdulillah anak saya sangat menghormati kedua orangtuanya.MasyaAllah..semoga SMP Al Bayan Hidayatullah terus mampu mencetak generasi” yang unggul dan terus bisa berkontribusi positif bagi bangsa dan negara kita.Aamiin Yaa Robbal Alamiin…🤲🏻🙏🏻{" "}
          </p>
        </div>
        <div className="testimonial-ratings">
          <div className="rating">
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star"></i>
            <p className="d-inline-block">
              4.5<span>ratings</span>
            </p>
          </div>
        </div>
        <div className="testimonial-users">
          <div className="imgbx">
            <img className="img-fluid" alt="" src={albhmTesti1} />
          </div>
          <div className="d-block">
            <h6>Ibrahim, SE</h6>
            <p>PNS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Slide2 = () => {
  return (
    <div className="swiper-slide">
      <div className="testimonial-item-five">
        <div className="testimonial-quote">
          <img className="quote img-fluid home3Slide" alt="" src={Quote} />
        </div>
        <div className="testimonial-content">
          <p>
            Mereka SD Integral Al Bayan menyediakan lingkungan yang mendukung, dengan metode pembelajaran yang fleksibel dan adaptif. Para guru dilatih untuk memahami kebutuhan khusus anak-anak ini, memberikan perhatian ekstra serta strategi yang sesuai untuk membantu mereka fokus dan berkembang.{" "}
          </p>
        </div>
        <div className="testimonial-ratings">
          <div className="rating">
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star"></i>
            <p className="d-inline-block">
              4.5<span>ratings</span>
            </p>
          </div>
        </div>
        <div className="testimonial-users">
          <div className="imgbx">
            <img className="" alt="" src={albhmTesti2} />
          </div>
          <div className="d-block">
            <h6>Ir. Evi Aprianti, ST., PhD., IPM</h6>
            <p>Dosen Sekolah Pascasarjana
Universitas Hasanuddin</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Slide3 = () => {
  return (
    <div className="swiper-slide">
      <div className="testimonial-item-five">
        <div className="testimonial-quote">
          <img className="quote img-fluid home3Slide" alt="" src={Quote} />
        </div>
        <div className="testimonial-content">
          <p>
            Pendidikan agama adalah prioritas utama dalam mencari sekolah untuk anak saya. Alhamdulillah Dua anak saya sekolah di SD Integral Al Bayan menjadi senang dengan program murojaah Al Qur&apos;an  dan rajin sholat berjamaan. Semoga Istiqomah selalu, sehingga bisa menjadi putra yang berkarakter Qur&apos;ani.{" "}
          </p>
        </div>
        <div className="testimonial-ratings">
          <div className="rating">
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star filled"></i>
            <i className="fas fa-star"></i>
            <p className="d-inline-block">
              4.8<span>ratings</span>
            </p>
          </div>
        </div>
        <div className="testimonial-users">
          <div className="imgbx">
            <img className="" alt="" src={albhmTesti3} />
          </div>
          <div className="d-block">
            <h6>{'Prof. Ahmad Rizal Sultan, S.T.,M.T., Ph.D'}</h6>
            <p>Dosen Politeknik Negeri Ujung Pandang / Ketua Jurusan Teknik Elektro-PNUP</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Testimonial2 = () => {

  return (
    <>

      <Swiper
        modules={[Navigation, Pagination, EffectCoverflow]}
        effect="coverflow"
        direction="horizontal"
        loop={false}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        initialSlide={2}
        speed={400}
        navigation={{
          prevEl: ".slide-prev-btn",
          nextEl: ".slide-next-btn",
        }}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 10,
          slideShadows: true,
        }}
      >
        <SwiperSlide>
          <Slide1 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide2 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide3 />
        </SwiperSlide>
      </Swiper>
      <div className="testimonial-bottom-nav">
        <div className="slide-next-btn testimonial-next-pre">
           <i className="fas fa-arrow-left "></i>
         </div>
        <div className="slide-prev-btn testimonial-next-pre">
           <i  className="fas fa-arrow-right"></i>
        </div>
      </div>
    </>
  );
};
