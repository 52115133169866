import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { albhmSlider1, albhmSlider2, albhmSlider3, albhmSlider4, albhmSlider5, albhmSlider6} from "../../imagepath";
import { Link } from "react-router-dom";

const BannerSlider = () => {
  var settings = {
    autoWidth: false,
    items: 1,
    margin: 0,
    dots: true,
    nav: false,

    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
	autoplay: true,
	autoplayTimeout: 5000,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    //   1920: {
    //     items: 1,
    //   },
    },
  };

  const blogs = [
	albhmSlider1,
	albhmSlider4,
	albhmSlider3,
	albhmSlider5,
	albhmSlider2,
	albhmSlider6,
  ]

  return (
    <OwlCarousel
      {...settings}
      className="owl-carousel owl-theme aos fullscreen-carousel"
      data-aos="fade-up"
    >

		{
			blogs.map((blog, index) => (
				<div className="item" key={index} style={{  width: '100vw' }}>
					<div className="">
					<Link to="">
						<img className="" alt="" src={blog}  />
					</Link>
					</div>

				</div>
			))
		}
    </OwlCarousel>
  );
};

export default BannerSlider;
